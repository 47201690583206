import api from '../../components/api/cli';
import {ACTION_LOADING_START, ACTION_LOADING_END} from '../../components/loader/Reducer';

export const ACTION_TRANSLATIONS_LANGS_FETCH_SUCCESS = 'ACTION_TRANSLATIONS_LANGS_FETCH_SUCCESS';
export const ACTION_TRANSLATIONS_LANGS_FETCH_FAIL = 'ACTION_TRANSLATIONS_LANGS_FETCH_FAIL';

export const ACTION_ADD_TRANSLATIONS_SUCCESS = 'ACTION_ADD_TRANSLATIONS_SUCCESS';
export const ACTION_ADD_TRANSLATIONS_FAIL = 'ACTION_ADD_TRANSLATIONS_FAIL';

export const ACTION_FETCH_NOT_TRANSLATED_WORDS_SUCCESS = 'ACTION_FETCH_NOT_TRANSLATED_WORDS_SUCCESS';
export const ACTION_FETCH_NOT_TRANSLATED_WORDS_FAIL = 'ACTION_FETCH_NOT_TRANSLATED_WORDS_FAIL';
export const ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH = 'ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH';
export const ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY = 'ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY';

export const fetchLanguages = () => dispatch => {
    dispatch({type: ACTION_LOADING_START});
    return api()
        .get('/api/translator/languages/list')
        .then(resp => {
            if (resp.data) {
                dispatch({
                    type: ACTION_TRANSLATIONS_LANGS_FETCH_SUCCESS,
                    payload: resp.data
                });
            }
            dispatch({type: ACTION_LOADING_END});
        })
        .catch(err => {
            dispatch({type: ACTION_TRANSLATIONS_LANGS_FETCH_FAIL});
            dispatch({type: ACTION_LOADING_END});
        });
};

export const addTranslations = (data) => dispatch => {
    dispatch({type: ACTION_LOADING_START});
    return api()
        .post('/api/admin/set-google-one', {data: data})
        .then(resp => {
            if (resp.data) {
                dispatch({
                    type: ACTION_ADD_TRANSLATIONS_SUCCESS,
                    payload: {data: resp.data}
                });
            }
            dispatch({type: ACTION_LOADING_END});
        })
        .catch(err => {
            dispatch({
                type: ACTION_ADD_TRANSLATIONS_FAIL,
                payload: {error: 'Something went wrong ...'}
            });
            dispatch({type: ACTION_LOADING_END});
        });
};

export const fetchNotTranslatedWords = (langFrom, langTo, limit = 30) => dispatch => {
    dispatch({type: ACTION_LOADING_START});
    return api()
        .get(
            '/api/admin/get-without-google-one/lang=' + langFrom + '&translationLang=' + langTo + '&limit=' + limit
        )
        .then(resp => {
            if (resp.data) {
                dispatch({
                    type: ACTION_FETCH_NOT_TRANSLATED_WORDS_SUCCESS,
                    payload: {
                        data: resp.data,
                    }
                });
            }
            dispatch({type: ACTION_LOADING_END});
        })
        .catch(err => {
            dispatch({
                type: ACTION_FETCH_NOT_TRANSLATED_WORDS_FAIL,
                payload: {error: 'Something went wrong ...'}
            });
            dispatch({type: ACTION_LOADING_END});
        });
};
