import {connect} from "react-redux";
import React, {useEffect} from "react";
import LanguageCard from './components/LanguageCard';
import {fetchLanguages} from './Actions';
import {Chip, Typography} from '@material-ui/core';

function TranslationsPage({langs, stats, fetchLangs}) {

    useEffect(() => {
        if (langs.length <= 0) {
            fetchLangs();
        }
    });

    return (
        <div className="">
            <div>
                <div style={{
                    float:'left',
                    width:'10px',
                    height: '40px',
                    backgroundColor:'#f50057',
                }} />
                <h1 style={{
                    marginLeft: '10px',
                    paddingLeft: '10px',
                }}>{'Translations Statistics'}</h1>
            </div>
            <div style={{ width: '100%' }}>
                {
                    langs.map((lang) => {
                        if (!lang.isTranslatable) {
                            return null;
                        }
                        const inner = langs.map((lang2) => {
                            if (lang.lang !== lang2.lang) {
                                const stat = stats.find(el => el.lang === lang.id && el.translationLang === lang2.id);
                                return <LanguageCard lang={lang} translationLang={lang2} stat={stat} />;
                            }
                        })
                        return <>
                            <div style={{clear: "both"}}>
                                <Chip label={lang.enTitle} color={"secondary"} />
                            </div>
                            {inner}
                        </>
                    })
                }
            </div>
        </div>
    );
}

export default connect(
    state => ({
        langs: state.translations.langs,
        stats: state.translations.stats,
    }),
    dispatch => ({
        fetchLangs: () => dispatch(fetchLanguages()),
    })
)(TranslationsPage);
