import api from '../../components/api/cli';
import auth from '../../components/auth/AuthService';
import {ACTION_LOADING_START, ACTION_LOADING_END} from '../../components/loader/Reducer';

export const ACTION_SIGN_IN = 'SIGN_IN';
export const ACTION_SIGN_OUT = 'SIGN_OUT';

export const signIn = (params) => {
    return dispatch => {
        dispatch({type: ACTION_LOADING_START});
        return api()
            .post('/module/auth/sign-in', params)
            .then(resp => {
                if (resp.data && resp.data.user) {
                    if (resp.data.accessToken) {
                        auth.signIn(resp.data);
                    }
                    dispatch({
                        type: ACTION_SIGN_IN,
                        payload: resp.data.user
                    });
                }
                dispatch({type: ACTION_LOADING_END});
            })
            .catch(err => {
                dispatch({type: ACTION_LOADING_END});
            });
    }
};
