import {TableCell, TableRow} from "@material-ui/core";
import * as React from "react";

function DataGridHeadSort(props) {
    return (
        <TableRow>
            {props.columns.map((column) => (
                <TableCell>{column.headerName}</TableCell>
            ))}
            <TableCell width={105}></TableCell>
        </TableRow>
    );
}

export default DataGridHeadSort;
