import {ACTION_SIGN_IN, ACTION_SIGN_OUT} from './Actions';
import auth from '../../components/auth/AuthService';

const initialState = {
    isGuest: !auth.isAuthenticated(),
    user: {}
}

export default function SignInReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_SIGN_IN:
            return {
                isGuest: false,
                user: action.payload.user
            };
        case ACTION_SIGN_OUT:
            return state;
        default:
            return state;
    }
}