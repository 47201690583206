import * as React from "react";
import {Table, TableContainer} from "@material-ui/core";
import DataGridHead from './DataGridHead';
import DataGridBody from "./DataGridBody";

function DataGrid(props) {
    return (
        <div style={{ width: '100%' }}>
            <TableContainer>
                <Table>
                    <DataGridHead columns={props.columns} />
                    <DataGridBody
                        columns={props.columns}
                        items={props.items}
                        viewUrl={props.viewUrl}
                        editUrl={props.editUrl}
                        deleteUrl={props.deleteUrl}
                    />
                </Table>
            </TableContainer>
        </div>
    );
}

export default DataGrid;
