import {connect} from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import BackendNav from "../components/panels/BackendNav";
import {Route, Switch} from "react-router-dom";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import IndexPage from "./Index/Page";
import UsersGridPage from "./Users/GridPage";
import UsersEditPage from "./Users/EditPage";
import UsersViewPage from "./Users/ViewPage";
import GatesGridPage from "./Gates/GridPage";
import SignInPage from "./SignIn/Page";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TranslationsPage from "./Translations/Page";
import FormPage from "./Translations/FormPage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function Layout(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            {!props.isGuest ? (<BackendNav/>) : ('')}
            <main className={classes.content}>
                {!props.isGuest ? (<div className={classes.toolbar} />) : ('')}
                <Switch>
                    <ProtectedRoute exact path={'/admin'}><IndexPage /></ProtectedRoute>
                    <ProtectedRoute exact path={'/admin/users'}><UsersGridPage /></ProtectedRoute>
                    <ProtectedRoute exact path={'/admin/users/edit/:id'}><UsersEditPage /></ProtectedRoute>
                    <ProtectedRoute exact path={'/admin/users/view/:id'}><UsersViewPage /></ProtectedRoute>
                    <ProtectedRoute exact path={'/admin/gates'}><GatesGridPage /></ProtectedRoute>
                    <ProtectedRoute exact path={'/admin/translations'}><TranslationsPage /></ProtectedRoute>
                    <ProtectedRoute exact path={'/admin/translations/:fromLang/:toLang'}>
                        <FormPage />
                    </ProtectedRoute>
                    <Route path={'/admin/login'}><SignInPage /></Route>
                </Switch>
            </main>
        </div>
    );
}

export default connect(
    state => ({
        isGuest: state.signIn.isGuest,
    }),
    null
)(Layout);