import {Button, Grid, MenuItem, Select, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import CountryFlag from "./components/CountryFlag";
import {Link, useParams} from "react-router-dom";
import * as yup from 'yup';
import { useFormik } from 'formik';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {useDispatch, useSelector} from 'react-redux';
import {
    ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH,
    ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY,
    addTranslations,
    fetchNotTranslatedWords
} from './Actions'

const validationSchema = yup.object({
    words: yup.string('Enter words for translation').required('Words are required'),
    translations: yup.string('Enter translations').required('Translations are required'),
});

export default function FormPage({}) {

    let {fromLang, toLang} = useParams();
    const dispatch = useDispatch();
    const [lastLang, setLastLang] = useState('');
    const [limit, setLimit] = useState(30);

    const {
        notTranslatedWords,
        notTranslatedWordsLoaded,
    } = useSelector(state => state.translations);

    const formik = useFormik({
        initialValues: {
            words: '',
            translations: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            let words = values.words.split('\n').filter(item => item.length > 0);
            let translations = values.translations.split('\n').filter(item => item.length > 0);

            if (words.length === translations.length) {
                let data = [];
                for (let i = 0; i < words.length; i++) {
                    data.push({
                        lang: fromLang,
                        word: words[i].toLowerCase(),
                        translationLang: toLang,
                        translationWord: translations[i].toLowerCase(),
                    });
                }
                if (data.length > 0) {
                    dispatch(addTranslations(data)).then(
                        () => setTimeout(() => dispatch({type: ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH}), 1000)
                    );

                }
            }
        },
    });

    useEffect(() => {
        if (lastLang !== fromLang + '-' + toLang) {
            setLastLang(fromLang + '-' + toLang);
            dispatch({type: ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY});
        }
        if (!notTranslatedWordsLoaded) {
            dispatch(fetchNotTranslatedWords(fromLang, toLang, limit));
            formik.values.translations = '';
        }
    }, [notTranslatedWords, notTranslatedWordsLoaded, limit]);

    formik.values.words = notTranslatedWords.join('\n');

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <Link to={'/admin/translations'}
                              style={{color: 'white', textDecoration: 'none', display: 'block'}}
                        >
                            <KeyboardBackspaceIcon />
                        </Link>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={limit}
                            label="Age"
                            onChange={e => {
                                setLimit(e.target.value);
                                dispatch({type: ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY});
                            }}
                        >
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={60}>60</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                        </Select>
                        <CountryFlag lang={fromLang} style={{width: '25px', height: '20px', marginRight: '10px'}} />
                        <TextField
                            id="translations-words"
                            label="Standard"
                            multiline
                            rows={limit}
                            name="words"
                            style={{border: '1px solid white', marginRight: '10px', width: '500px'}}
                            value={formik.values.words}
                            onChange={formik.handleChange}
                            error={formik.touched.words && Boolean(formik.errors.words)}
                            helperText={formik.touched.words && formik.errors.words}
                        />
                        <CountryFlag lang={toLang} style={{width: '25px', height: '20px', marginRight: '10px'}} />
                        <TextField
                            id="translations-words-translations"
                            label="Standard"
                            multiline
                            rows={limit}
                            name="translations"
                            style={{border: '1px solid white', marginRight: '10px', width: '500px'}}
                            value={formik.values.translations}
                            onChange={formik.handleChange}
                            error={formik.touched.translations && Boolean(formik.errors.translations)}
                            helperText={formik.touched.translations && formik.errors.translations}
                        />
                        <Button variant="contained" color="primary" type="submit">
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}