import {LinearProgress} from "@material-ui/core";
import React from "react";
import {connect} from "react-redux";

function LoaderComponent(props) {
    return (
        props.loading ? <LinearProgress /> : null
    );
}

export default connect(
    state => ({
        loading: state.loader.loading,
    }),
    null
)(LoaderComponent);
