import React from "react";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DraftsIcon from '@material-ui/icons/Drafts';
import TranslateIcon from '@material-ui/icons/Translate';

export const NAV_DEVIDER = 'NAV_DEVIDER';

export default {
    width: 240,
    items: [
        {
            to: '/admin',
            text: 'Dashboard',
            icon: <DashboardIcon />
        },
        {text: NAV_DEVIDER},
        {
            to: '/admin/users',
            text: 'Users',
            icon: <SupervisedUserCircleIcon />
        },
        {
            to: '/admin/translations',
            text: 'Translations',
            icon: <TranslateIcon />
        },
        {
            to: '/admin/gates',
            text: 'Gates',
            icon: <AccountTreeIcon />
        },
        {text: NAV_DEVIDER},
        {
            to: '/admin/logs',
            text: 'Logs',
            icon: <FindInPageIcon />
        },
        {
            to: '/admin/emails',
            text: 'Emails',
            icon: <MailIcon />
        },
        {
            to: '/admin/files',
            text: 'Files',
            icon: <InboxIcon />
        },
        {
            to: '/admin/drafts',
            text: 'Drafts',
            icon: <DraftsIcon />
        },
    ],
}
