import api from '../../components/api/cli';
import {ACTION_LOADING_START, ACTION_LOADING_END} from '../../components/loader/Reducer';

export const ACTION_GATES_LOAD_SUCCESS = 'ACTION_GATES_LOAD_SUCCESS';
export const ACTION_GATES_LOAD_FAIL = 'ACTION_GATES_LOAD_FAIL';

const loadGatesList = dispatch => (params = {}) => {
    dispatch({type: ACTION_LOADING_START});
    return api()
        .get('/module/gate/list', params)
        .then(resp => {
            if (resp.data) {
                dispatch({
                    type: ACTION_GATES_LOAD_SUCCESS,
                    payload: {list: resp.data}
                });
            }
            dispatch({type: ACTION_LOADING_END});
        })
        .catch(err => {
            dispatch({type: ACTION_GATES_LOAD_FAIL});
            dispatch({type: ACTION_LOADING_END});
        });
};

export const handleInitialization = () => dispatch => {
    loadGatesList(dispatch)();
};

