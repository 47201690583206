import {applyMiddleware, combineReducers, createStore} from "redux";
import LoaderReducer from "../../components/loader/Reducer";
import SignInReducer from "../SignIn/Reducer";
import {UsersGridReducer} from "../Users/Reducers";
import {GatesReducer} from "../Gates/Reducer";
import thunk from "redux-thunk";
import {TranslationsReducer} from "../Translations/Reducer"

const rootReducer = combineReducers({
    loader: LoaderReducer,
    signIn: SignInReducer,
    users: UsersGridReducer,
    gates: GatesReducer,
    translations: TranslationsReducer,
});
const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;