import {TextField, Button} from "@material-ui/core";
import './Styles.css';
import {connect} from "react-redux";
import React from "react";
import {signIn} from './Actions';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Redirect} from 'react-router-dom';

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

function SignInPage(props) {
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.handleSignIn(values);
        },
    });

    return (!props.isGuest
        ? <Redirect to="/admin" />
        : <div className="App">
            <form className="form" onSubmit={formik.handleSubmit}>
                <TextField
                    id="sign-in-email"
                    label="email"
                    name={'email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    id="sign-in-password"
                    label="password"
                    name={'password'}
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                />

                <Button variant="contained" color="primary" className="form__custom-button" type="submit">
                    Log in
                </Button>
            </form>
        </div>
    );
}

export default connect(
    state => ({
        isGuest: state.signIn.isGuest,
    }),
    dispatch => {
        return {
            handleSignIn: data => {
                dispatch(signIn(data));
            }
        }
    }
    )(SignInPage);
