import React from "react";
import {Route, Switch} from "react-router-dom";
import IndexPage from "./Pages/IndexPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";

function Layout(props) {
    return (
        <div className="App">
            <Switch>
                <Route exact path={'/'}><IndexPage /></Route>
                <Route path={'/privacy-policy'}><PrivacyPolicyPage /></Route>
            </Switch>
        </div>
    );
}

export default Layout;