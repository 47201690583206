import {TableCell, TableRow, TextField} from "@material-ui/core";
import * as React from "react";
import SelectFilter from "./fiilters/SelectFilter";
import TextFilter from "./fiilters/TextFilter";

function DataGridHeadFilter(props) {
    function buildFilter(column) {
        if (column.filterType && column.filterType === 'select') {
            return <SelectFilter />;
        }
        return <TextFilter column={column} />;
    }

    return (
        <TableRow>
            {props.columns.map((column) => (
                <TableCell>
                    {buildFilter(column)}
                </TableCell>
            ))}
            <TableCell></TableCell>
        </TableRow>
    );
}

export default DataGridHeadFilter;
