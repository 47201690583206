export default {
    isAuthenticated: () => {
        const token = localStorage.getItem('auth_token');
        if (token && token.length > 0) {
            const tokenTime = localStorage.getItem('auth_token_dt');
            if (tokenTime) {
                const tTime = new Date(tokenTime);
                const now = new Date();
                const diff = (now.getTime() - tTime.getTime()) / 1000 / 60 / 60;
                if (diff <= 24) {
                    return true;
                } else {
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('auth_token_dt');
                    localStorage.removeItem('auth_user');
                }
            }
        }

        return false;
    },
    signIn: (data) => {
        if (data.accessToken) {
            localStorage.setItem('auth_token', data.accessToken);
            localStorage.setItem('auth_token_dt', (new Date()).toString());
            if (data.user) {
                localStorage.setItem('auth_user', data.user);
            }
        }
    },
    signOut: () => {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_token_dt');
        localStorage.removeItem('auth_user');
    },
    getToken: () => {
        return localStorage.getItem('auth_token');
    },
    getUser: () => {
        return localStorage.getItem('auth_user');
    },
}
