import React from "react";
import DataGrid from "../../components/grid/DataGrid";
import {connect} from "react-redux";
import {handleInitialization, handleFilter, handlePage, handleSize, handleSort} from "./Actions";
import AddIcon from "@material-ui/icons/Add";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

function UsersGridPage(props) {
    if (!props.listLoaded) {
        props.handleInitialization();
    }

    let columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            type: 'text',
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            type: 'text',
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 120,
            type: 'text',
            filterType: 'select',
        },
    ];

    return (
        <div className="">
            <div>
                <div style={{
                    float:'left',
                    width:'10px',
                    height: '40px',
                    backgroundColor:'#f50057',
                }} />
                <Link to={'/admin/users/add'}>
                    <Button variant="contained" color="primary" style={{
                        float: 'right',
                        marginTop: '2px',
                    }} startIcon={<AddIcon />}>
                        Create
                    </Button>
                </Link>
                <h1 style={{
                    marginLeft: '10px',
                    paddingLeft: '10px',
                }}>{'Users page'}</h1>
            </div>
            <DataGrid
                items={props.listItems}
                columns={columns}
                size={props.listPageSize}
                page={props.listPage}
                handleSort={props.handleSort}
                handleFilter={props.handleFilter}
                handlePage={props.handlePage}
                handleSize={props.handleSize}
                addUrl={'/admin/users/add'}
                viewUrl={(item) => {
                    return '/admin/users/' + item.id
                }}
                editUrl={(item) => {
                    return '/admin/users/edit/' + item.id
                }}
                deleteUrl={(item) => {
                    return '/admin/users/edit/' + item.id
                }}
            />
        </div>
    );
}

export default connect(
    state => ({
        listPage: state.users.page,
        listPageSize: state.users.pageSize,
        listSort: state.users.sort,
        listFilters: state.users.filters,
        listItems: state.users.items,
        listLoaded: state.users.loaded,
    }),
    dispatch => {
        return {
            handleInitialization: () => {
                dispatch(handleInitialization())
            },
            handleFilter: (filters) => {
                dispatch(handleFilter(filters))
            },
            handlePage: page => {
                dispatch(handlePage(page))
            },
            handleSize: size => {
                dispatch(handleSize(size))
            },
            handleSort: sort => {
                dispatch(handleSort(sort))
            },
        }
    }
)(UsersGridPage);
