import React from "react";
import ReactCountryFlag from "react-country-flag";

export default function CountryFlag({lang, style = {}}) {

    let _lang = lang;
    switch (lang) {
        case 'en':
            _lang = 'gb';
            break;
        case 'uk':
            _lang = 'ua';
            break;
        case 'el':
            _lang = 'gr';
            break;
        case 'sv':
            _lang = 'se';
            break;
        case 'cs':
            _lang = 'cz';
            break;
        case 'ja':
            _lang = 'jp';
            break;
        case 'ko':
            _lang = 'kr';
            break;
        case 'zh-cn':
            _lang = 'cn';
            break;
        case 'zh-tw':
            _lang = 'cn';
            break;



        default:
            break;
    }

    return (
        <ReactCountryFlag countryCode={_lang} svg style={style} />
    );
}
