import * as React from "react";
import {TableBody, TableCell, TableRow} from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backendNavLink: {
        color: '#fff',
        textDecoration: 'none',
    }
}));

function DataGridBody(props) {
    const classes = useStyles();

    return (
        <TableBody>
            {props.items.map((item) => (
                <TableRow>
                    {props.columns.map((column) => {
                        return (
                            <TableCell>{column.value ? column.value(item) : (item[column.field] ?? null)}</TableCell>
                        );
                    })}
                    <TableCell>
                        {
                            props.viewUrl ? <span>
                                <Link to={props.viewUrl(item)} className={classes.backendNavLink}>
                                    <InsertDriveFileIcon/>
                                </Link>
                            </span> : null
                        }
                        {
                            props.editUrl ? <span>
                                <Link to={props.editUrl(item)} className={classes.backendNavLink}>
                                    <EditIcon/>
                                </Link>
                            </span> : null
                        }
                        {
                            props.deleteUrl ? <span>
                                <DeleteIcon color={'secondary'}/>
                            </span> : null
                        }
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
}

export default connect()(DataGridBody);
