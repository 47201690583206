import * as React from "react";
import TableHead from '@material-ui/core/TableHead';
import DataGridHeadSort from "./DataGridHeadSort";
import DataGridHeadFilter from "./DataGridHeadFilter";

function DataGridHead(props) {
    return (
        <TableHead>
            <DataGridHeadSort columns={props.columns} />
            <DataGridHeadFilter columns={props.columns} />
        </TableHead>
    );
}

export default DataGridHead;
