import {
    ACTION_TRANSLATIONS_LANGS_FETCH_SUCCESS,
    ACTION_TRANSLATIONS_LANGS_FETCH_FAIL,
    ACTION_ADD_TRANSLATIONS_SUCCESS,
    ACTION_ADD_TRANSLATIONS_FAIL,
    ACTION_FETCH_NOT_TRANSLATED_WORDS_SUCCESS,
    ACTION_FETCH_NOT_TRANSLATED_WORDS_FAIL,
    ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH,
    ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY
} from "./Actions";

const initialState = {
    langs: [],
    stats: [],
    loaded: false,
    notTranslatedWords: [],
    notTranslatedWordsLoaded: false,
}

export function TranslationsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TRANSLATIONS_LANGS_FETCH_SUCCESS:
            return {
                ...state,
                langs: action.payload.languages ?? [],
                stats: action.payload.stat ?? [],
                loaded: true,
            };
        case ACTION_ADD_TRANSLATIONS_SUCCESS:
            return {
                ...state,
                loaded: true,
            };
        case ACTION_FETCH_NOT_TRANSLATED_WORDS_SUCCESS:
            const notTranslatedWords = action.payload.data ?? [];
            return {
                ...state,
                notTranslatedWords: notTranslatedWords,
                notTranslatedWordsLoaded: true,
            };
        case ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH:
            return {
                ...state,
                notTranslatedWordsLoaded: false,
            };
        case ACTION_FETCH_NOT_TRANSLATED_WORDS_REFRESH_FULLY:
            return {
                ...state,
                notTranslatedWordsLoaded: false,
            };
        case ACTION_TRANSLATIONS_LANGS_FETCH_FAIL:
        case ACTION_ADD_TRANSLATIONS_FAIL:
        case ACTION_FETCH_NOT_TRANSLATED_WORDS_FAIL:
            return {
                ...state,
                loaded: true,
            };
        default:
            return state;
    }
}
