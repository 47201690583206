export const ACTION_LOADING_START = 'LOADING_START';
export const ACTION_LOADING_END = 'LOADING_END';

const initialState = {
    loading: false
}

export default function LoaderReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_LOADING_END:
            return {
                loading: false
            };
        case ACTION_LOADING_START:
            return {
                loading: true
            };
        default:
            return state;
    }
}