import React from "react";
import {AppBar, Box, Container, Toolbar, Typography} from "@material-ui/core";

function PrivacyPolicyPage() {
    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" color={'transparent'}>
                    <Toolbar>
                        <Container>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                            >
                                <h1>Our Privacy Policy</h1>
                            </Typography>
                        </Container>
                    </Toolbar>
                </AppBar>
            </Box>
            <main>
                <Container>
                    <p>
                        At <strong>vmi699989.contaboserver.net</strong>, accessible from
                        &nbsp;<strong>vmi699989.contaboserver.net</strong> domain, one of our main priorities is the privacy of our
                        visitors. This Privacy Policy document contains types of information that is collected and
                        recorded by <strong>vmi699989.contaboserver.net</strong> and our chrome web browser extension
                        "Vocabulary", accessible from
                        &nbsp;<strong>
                            <a target={'_blank'} href={'https://chrome.google.com/webstore/detail/vocabualry/ecdmabgijkhbklbcbiocdmbchcmfhkln'}>
                                chrome.google.com/webstore/detail/vocabualry/ecdmabgijkhbklbcbiocdmbchcmfhkln
                            </a>
                        </strong> and how we use it.
                    </p>
                    <p>
                        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                    </p>
                    <p>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our website
                        and users of our chrome extension with regards to the information that they shared and/or collect
                        in <strong>vmi699989.contaboserver.net</strong>. This policy is not applicable to any information collected offline or via channels
                        other than this website or vocabulary chrome extension.
                    </p>

                    <h1>Consent</h1>

                    <p>By using our website or our chrome web browser extension, you hereby consent to our Privacy Policy and agree to its terms.</p>


                    <h1>Information we collect</h1>

                    <p>
                        The personal information that you are asked to provide, and the reasons why you are asked to provide
                        it, will be made clear to you at the point we ask you to provide your personal information.
                    </p>
                    <p>
                        If you contact us directly, we may receive additional information about you such as your name, email
                        address, the contents of the message and/or attachments you may send us, and any other information
                        you may choose to provide.
                    </p>
                    <p>
                        When you register for an Account, we may ask for your contact information, including items such as
                        name, email address.
                    </p>

                    <h1>How we use your information</h1>

                    <p>We use the information we collect in various ways, including to:</p>

                    <ul>
                        <li>Provide, operate, and maintain our website and chrome extension</li>
                        <li>Improve, personalize, and expand our website and chrome extension</li>
                        <li>Understand and analyze how you use our website and chrome extension</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>
                            Communicate with you, either directly or through one of our partners, including for customer
                            service, to provide you with updates and other information relating to the website or chrome
                            extension
                        </li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                    </ul>

                    <h1>Log Files</h1>

                    <p>
                        <strong>vmi699989.contaboserver.net</strong> follows a standard procedure of using log files. These files log
                        visitors when they visit websites. All hosting companies do this and a part of hosting services'
                        analytics. The information collected by log files include internet protocol (IP) addresses,
                        browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and
                        possibly the number of clicks. These are not linked to any information that is personally
                        identifiable. The purpose of the information is for analyzing trends, administering the site,
                        tracking users' movement on the website, and gathering demographic information.
                    </p>

                    <h1>Cookies and Web Beacons</h1>

                    <p>
                        Like any other website, <strong>vmi699989.contaboserver.net</strong> uses 'cookies'. These cookies are used to
                        store information including visitors' preferences, and the pages on the website that the visitor
                        accessed or visited. The information is used to optimize the users' experience by customizing
                        our web page content based on visitors' browser type and/or other information.
                    </p>

                    {/*<h1>Google DoubleClick DART Cookie</h1>

                    <p>
                        Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies,
                        to serve ads to our site visitors based upon their visit to www.website.com and other sites on
                        the internet. However, visitors may choose to decline the use of DART cookies by visiting the
                        Google ad and content network Privacy Policy at the following URL –
                        <a target={'_blank'} href={'https://policies.google.com/technologies/ads'}>
                            https://policies.google.com/technologies/ads
                        </a>
                    </p>*/}

                    {/*<h1>Our Advertising Partners</h1>

                    <p>
                        Some of advertisers on our site may use cookies and web beacons. Our advertising partners are
                        listed below. Each of our advertising partners has their own Privacy Policy for their policies
                        on user data. For easier access, we hyperlinked to their Privacy Policies below.
                        <ul>
                            <li>
                                Google -&nbsp;
                                <a target={'_blank'} href={'https://policies.google.com/technologies/ads'}>
                                    https://policies.google.com/technologies/ads
                                </a>
                            </li>
                        </ul>
                    </p>*/}

                    {/*<h1>Advertising Partners Privacy Policies</h1>

                    <p>
                        You may consult this list to find the Privacy Policy for each of the advertising partners of
                        <strong>vmi699989.contaboserver.net</strong>.
                    </p>

                    <p>
                        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons
                        that are used in their respective advertisements and links that appear on
                        <strong>vmi699989.contaboserver.net</strong>, which are sent directly to users' browser. They automatically
                        receive your IP address when this occurs. These technologies are used to measure the
                        effectiveness of their advertising campaigns and/or to personalize the advertising content that
                        you see on websites that you visit.
                    </p>

                    <p>
                        Note that <strong>vmi699989.contaboserver.net</strong> has no access to or control over these cookies that are
                        used by third-party advertisers.
                    </p>*/}

                    {/*<h1>Third Party Privacy Policies</h1>

                    <p>
                        <strong>vmi699989.contaboserver.net's</strong> Privacy Policy does not apply to other advertisers or websites.
                        Thus, we are advising you to consult the respective Privacy Policies of these third-party ad
                        servers for more detailed information. It may include their practices and instructions about how
                        to opt-out of certain options.
                    </p>

                    <p>
                        You can choose to disable cookies through your individual browser options. To know more detailed
                        information about cookie management with specific web browsers, it can be found at the browsers'
                        respective websites.
                    </p>*/}

                    <h1>CCPA Privacy Rights (Do Not Sell My Personal Information)</h1>

                    <p>
                        Under the CCPA, among other rights, consumers have the right to:
                    </p>

                    <p>
                        Request that a business that collects a consumer's personal data disclose the categories and
                        specific pieces of personal data that a business has collected about consumers.
                    </p>

                    <p>
                        Request that a business delete any personal data about the consumer that a business has collected.
                    </p>

                    <p>
                        Request that a business that sells a consumer's personal data, not sell the consumer's personal
                        data.
                    </p>

                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>

                    <h1>GDPR Data Protection Rights</h1>

                    <p>
                        We would like to make sure you are fully aware of all of your data protection rights. Every user
                        is entitled to the following:
                    </p>

                    <p>
                        The right to access – You have the right to request copies of your personal data. We may charge
                        you a small fee for this service.
                    </p>

                    <p>
                        The right to rectification – You have the right to request that we correct any information you
                        believe is inaccurate. You also have the right to request that we complete the information you
                        believe is incomplete.
                    </p>

                    <p>
                        The right to erasure – You have the right to request that we erase your personal data, under
                        certain conditions.
                    </p>

                    <p>
                        The right to restrict processing – You have the right to request that we restrict the processing
                        of your personal data, under certain conditions.
                    </p>

                    <p>
                        The right to object to processing – You have the right to object to our processing of your
                        personal data, under certain conditions.
                    </p>

                    <p>
                        The right to data portability – You have the right to request that we transfer the data that we
                        have collected to another organization, or directly to you, under certain conditions.
                    </p>

                    <p>
                        If you make a request, we have one month to respond to you. If you would like to exercise any of
                        these rights, please contact us.
                    </p>

                    <h1>Children's Information</h1>

                    <p>
                        Another part of our priority is adding protection for children while using the internet. We
                        encourage parents and guardians to observe, participate in, and/or monitor and guide their online
                        activity.
                    </p>

                    <p>
                        <strong>vmi699989.contaboserver.net's</strong> does not knowingly collect any Personal Identifiable Information
                        from children under the age of 13. If you think that your child provided this kind of
                        information on our website, we strongly encourage you to contact us immediately and we will do
                        our best efforts to promptly remove such information from our records.
                    </p>

                </Container>
            </main>
            <footer></footer>
        </div>
    );
}

export default PrivacyPolicyPage;