import React from "react";
import logo from "../../logo.svg";

function IndexPage() {
    return (
        <div>
            <header className="App-header">Hello!</header>
            <main></main>
            <footer></footer>
        </div>
    );
}

export default IndexPage;
