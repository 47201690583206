import {useParams} from "react-router-dom";
import {TextField} from "@material-ui/core";

function UsersViewPage(props) {
    const {id} = useParams();
    let columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            type: 'text',
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 200,
            type: 'text',
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 120,
            type: 'text',
            filterType: 'select',
        },
    ];

    return (
        <div>
            <h1>Users view page</h1>
            {columns.map((column) => {
                if (column.type === 'text') {
                    return (
                        <div>
                            <div>{column.headerName}</div>
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
}

export default UsersViewPage;
