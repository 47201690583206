import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Chip,
    IconButton,
    Typography,
} from "@material-ui/core";
import {Link} from "react-router-dom";
import CountryFlag from "./CountryFlag";
import AddIcon from "@material-ui/icons/Add";

export default function LanguageCard({lang, translationLang, stat}) {

    return (
        <Card style={{maxWidth: '90px', margin: '5px', float: 'left'}}>
            <CardMedia
                className={''}
                image={''}
                title={''}
                style={{height: '34px'}}
            >
                <CountryFlag lang={lang.lang === 'ru' ? 'uk' : lang.lang} style={{width: '45px', height: '34px'}} />
                <CountryFlag lang={translationLang.lang === 'ru' ? 'uk' : translationLang.lang} style={{width: '45px', height: '34px'}} />
            </CardMedia>
            <CardContent>
                <Chip label={translationLang.enTitle} color={"primary"} style={{marginBottom: "10px"}} />
                <Typography variant="body2" color="textSecondary" component="p">
                    {stat ? (stat.wordsCount ?? '-') : '-'}<br />
                    {stat ? (stat.emptyGoogleCount ?? '-') : '-'}<br />
                    {stat ? (stat.emptyGoogleOneCount ?? '-') : '-'}<br />
                    {stat ? (stat.notTranslatedCount ?? '-') : '-'}
                </Typography>
                <IconButton>
                    <Link
                        to={'/admin/translations/' + lang.lang + '/' + translationLang.lang}
                        style={{color: 'white', textDecoration: 'none', display: 'block'}}
                    >
                        <AddIcon />
                    </Link>
                </IconButton>
            </CardContent>
        </Card>
    );
}
