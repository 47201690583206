import api from '../../components/api/cli';
import {ACTION_LOADING_START, ACTION_LOADING_END} from '../../components/loader/Reducer';

export const ACTION_USERS_HANDLE_FILTER = 'ACTION_USERS_HANDLE_FILTER';
export const ACTION_USERS_HANDLE_SORT = 'ACTION_USERS_HANDLE_SORT';
export const ACTION_USERS_HANDLE_PAGE = 'ACTION_USERS_HANDLE_PAGE';
export const ACTION_USERS_HANDLE_SIZE = 'ACTION_USERS_HANDLE_SIZE';

export const ACTION_USERS_LOAD_START = 'ACTION_USERS_LOAD_START';
export const ACTION_USERS_LOAD_SUCCESS = 'ACTION_USERS_LOAD_SUCCESS';
export const ACTION_USERS_LOAD_FAIL = 'ACTION_USERS_LOAD_FAIL';

const loadUsersList = dispatch => (params = {}) => {
    dispatch({type: ACTION_LOADING_START});
    return api()
        .get('/module/user/list', params)
        .then(resp => {
            if (resp.data) {
                dispatch({
                    type: ACTION_USERS_LOAD_SUCCESS,
                    payload: {list: resp.data}
                });
            }
            dispatch({type: ACTION_LOADING_END});
        })
        .catch(err => {
            dispatch({type: ACTION_USERS_LOAD_FAIL});
            dispatch({type: ACTION_LOADING_END});
        });
};

export const handleInitialization = () => dispatch => {
    loadUsersList(dispatch)();
}

export const handleFilter = (filters) => dispatch => {
    // add to or delete from uri
    // reload users
}

export const handleSort = (sort) => dispatch => {
    // add to or delete from uri
    // reload users
}

export const handlePage = (page) => dispatch => {
    // add to or delete from uri
    // reload users
}

export const handleSize = (size = 20) => {
    // add to or delete from uri
    // reload users
}
