import {handleInitialization} from "./Actions";
import {connect} from "react-redux";
import DataGrid from "../../components/grid/DataGrid";
import React from "react";

function GatesGridPage(props) {
    if (!props.listLoaded) {
        props.handleInitialization();
    }

    let columns = [
        {
            field: 'authType',
            headerName: 'Auth method',
            width: 200,
            type: 'text',
            value: (item) => getAuthMethodItem(item.authType)
        },
        {
            field: 'method',
            headerName: 'Http method',
            width: 200,
            type: 'text',
            value: (item) => getMethodItem(item.method)
        },
        {
            field: 'url',
            headerName: 'Url',
            width: 200,
            type: 'text',
        },
        {
            field: 'host',
            headerName: 'Proxy Host',
            width: 200,
            type: 'text',
            value: (item) => item.server.host
        },
        {
            field: 'proxy',
            headerName: 'Proxy Uri',
            width: 200,
            type: 'text',
        },
    ];

    const getMethodColor = (method) => {
        switch (method) {
            case 'post':
                return '#78BC61';
            case 'patch':
                return '#50e3c2';
            case 'put':
                return '#E6C229';
            case 'delete':
                return '#ED6A5A';
            default:
                return '#3CAAB5';
        }
    };

    const getMethodItem = (method) => {
        return (
            <span style={{
                backgroundColor: getMethodColor(method),
                padding: '5px',
                fontSize: '14px',
                fontWeight: 700,
                width: '70px',
                textAlign: 'center',
                color: '#fff',
                marginRight: '0',
                display: 'inline-block'
            }}>
                {method.toUpperCase()}
            </span>
        );
    };

    const getAuthMethodColor = (method) => {
        switch (method) {
            case 'jwt':
                return 'orange';
            case 'token':
                return 'red';
            case 'bearer':
                return 'yellow';
            default:
                return 'red';
        }
    };

    const getAuthMethodItem = (method) => {
        return (
            <span style={{
                backgroundColor: getAuthMethodColor(method),
                padding: '5px',
                fontSize: '14px',
                fontWeight: 700,
                width: '70px',
                textAlign: 'center',
                color: '#fff',
                marginRight: '0',
                display: 'inline-block'
            }}>
                {method.toUpperCase()}
            </span>
        );
    };

    return (
        <div className="">
            <div>
                <div style={{
                    float:'left',
                    width:'10px',
                    height: '40px',
                    backgroundColor:'#f50057',
                }} />
                <h1 style={{
                    marginLeft: '10px',
                    paddingLeft: '10px',
                }}>{'Gates page'}</h1>
            </div>
            <DataGrid
                items={props.listItems}
                columns={columns}
                size={props.listPageSize}
                page={props.listPage}
                handleSort={props.handleSort}
                handleFilter={props.handleFilter}
                handlePage={props.handlePage}
                handleSize={props.handleSize}
            />
        </div>
    );
}

export default connect(
    state => ({
        listPage: state.gates.page,
        listPageSize: state.gates.pageSize,
        listSort: state.gates.sort,
        listFilters: state.gates.filters,
        listItems: state.gates.items,
        listLoaded: state.gates.loaded,
    }),
    dispatch => {
        return {
            handleInitialization: () => {
                dispatch(handleInitialization())
            },
        }
    }
)(GatesGridPage);
