import axios from 'axios';
import AuthService from "../auth/AuthService";

const cli = () => {

    let port = (parseInt(window.location.port) > 0
        && parseInt(window.location.port) === parseInt(process.env.REACT_APP_POSSIBLE_PORT))
        ? parseInt(process.env.REACT_APP_POSSIBLE_PORT) : '';

    return axios.create({
        baseURL: process.env.REACT_APP_DOMAIN + ':' + port,
        timeout: 20000,
        headers: {
            authorization: `bearer ` + AuthService.getToken(),
        }
    });
};

export default cli;
