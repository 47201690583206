import {TextField} from "@material-ui/core";
import * as React from "react";

function TextFilter(props) {
    return (
        <TextField id="outlined-basic" label={props.column.headerName} variant="outlined" size="small" />
    );
}

export default TextFilter;
