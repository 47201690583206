import {
    ACTION_USERS_LOAD_SUCCESS,
    ACTION_USERS_LOAD_FAIL
} from './Actions';
import SearchParams from '../../components/url/SearchParams';

let params = SearchParams.getAll();
const initialGridState = {
    page: params['page'] ?? 1,
    pageSize: params['pageSize'] ?? 10,
    filters: params['filters'] ?? {},
    sort: params['sort'] ?? '',
    items: [],
    loaded: false,
}

export function UsersGridReducer(state = initialGridState, action) {
    switch (action.type) {
        case ACTION_USERS_LOAD_SUCCESS:
            const items = action.payload.list ?? [];
            return {
                ...state,
                items: items,
                loaded: true,
            };
        case ACTION_USERS_LOAD_FAIL:
            return {
                ...state,
                loaded: true,
            };
        default:
            return state;
    }
}