let SearchParams = {
    getAll: () => {
        const query = new URLSearchParams(decodeURI(window.location.search))
        let params = {};
        for (let entry of query.entries()) {
            if (entry[0].indexOf('[') > -1) {
                entry[0] = entry[0].replace(']', '');
                let parts = entry[0].split('[');
                if (!params[parts[0]]) {
                    params[parts[0]] = {};
                }
                params[parts[0]][parts[1]] = entry[1];
            } else {
                params[entry[0]] = entry[1];
            }
        }

        return params;
    },
    setNew: (param, value) => {
        if (!param || !value) {
            return;
        }
        const path = window.location.pathname;
        let search = window.location.search;
        search += (search ? '&' : '?') + param + '=' + value;

        window.history.pushState({}, '', path + search);
    },
    pushHistory: (path, search = '') => {
        window.history.pushState({}, '', path + search);
    }
}

export default SearchParams;
