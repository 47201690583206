import './AppBack.css';
import {BrowserRouter} from 'react-router-dom';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import React from "react";
import {useMediaQuery} from "@material-ui/core";
import {Provider} from 'react-redux';
import LoaderComponent from "./components/loader/Component";
import Layout from "./Back/Layout";
import store from './Back/components/store';

function AppBack() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    /*type: prefersDarkMode ? 'dark' : 'light',*/
                    type: 'dark',
                },
            }),
        [prefersDarkMode],
    );

    return (
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <LoaderComponent />
                    <Layout />
                </BrowserRouter>
            </Provider>
        </MuiThemeProvider>
    );
}

export default AppBack;
