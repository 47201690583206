import SearchParams from '../../components/url/SearchParams';
import {
    ACTION_GATES_LOAD_FAIL,
    ACTION_GATES_LOAD_SUCCESS
} from "./Actions";

let params = SearchParams.getAll();
const initialState = {
    items: [],
    loaded: false,
    gate: null,
    redirect: false,
}

export function GatesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_GATES_LOAD_SUCCESS:
            const items = action.payload.list ?? [];
            return {
                ...state,
                items: items,
                loaded: true,
            };
        case ACTION_GATES_LOAD_FAIL:
            return {
                ...state,
                loaded: true,
            };
        default:
            return state;
    }
}
